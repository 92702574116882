<template>
  <div class="footer">
    <div class="footer-nav">
      <div class="container">
        <div class="warpper">
          <div class="left">
            <div class="logo" @click="goHome">
              <img src="~@/assets/images/logo2.png" alt="" />
            </div>
            <div class="phone">
              <a href="tel:1300 849 879"><i class="fa fa-phone"></i>&nbsp; 1300 849 879</a>
            </div>
          </div>
          <div class="nav">
            <div class="nav-title">Navigation</div>
            <div class="nav-list">
              <div class="nav-itm" v-for="(item, index) in navList" :key="item.id" @click="goNav(index, item.name)">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="page">
            <div class="page-title">We Buy Cars in</div>
            <div class="page-info">
              <div class="page-item" v-for="item in pageList" :key="item.id" @click="goPage(item.id)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="copy">
          © 2025 Quote My Cars Value.&nbsp;&nbsp;ABN 81 624 308 498,&nbsp;&nbsp;Dealer License:&nbsp;&nbsp;4295317
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          name: "Locations",
        },
        {
          id: 2,
          name: "FAQs",
        },
        {
          id: 3,
          name: "About",
        },
        {
          id: 4,
          name: "Get Started",
        },
      ],
      pageList: [
        {
          id: 4,
          name: "QLD",
        },
        {
          id: 8,
          name: "WA",
        },
        {
          id: 2,
          name: "NSW",
        },
        {
          id: 1,
          name: "ACT",
        },
        {
          id: 7,
          name: "VIC",
        },
        {
          id: 6,
          name: "TAS",
        },
        {
          id: 5,
          name: "SA",
        },
        {
          id: 3,
          name: "NT",
        },
      ],
    };
  },
  methods: {
    goNav(index, path) {
      if (index != 3) {
        this.$router.push({ name: path, query: { index } });
      } else {
        this.$router.push("/start");
      }
    },
    goHome() {
      this.$router.push("/");
    },
    goPage(id) {
      this.$router.push({ name: "Locations", query: { index: 0, id } });
    },
  },
};
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  background: #303030;

  .footer-nav {
    width: 100%;
    padding: 75px 0;
    box-sizing: border-box;

    .warpper {
      display: flex;
      justify-content: space-between;

      .left {
        .logo {
          width: 210px;
          margin-bottom: 80px;
          cursor: pointer;

          img {
            width: 100%;
          }
        }

        .phone {
          // width: 210px;
          padding: 11px 27px;
          box-sizing: border-box;
          background: #f5c03e;
          text-align: center;
          font-family: Poppins-SemiBold;
          font-weight: 600;
          font-size: 18px;
          border-radius: 6px;
          line-height: 21px;
          user-select: none;
          cursor: pointer;
          transition: all 0.3s;
          color: #404040;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
          }

          a:hover {
            color: #212121;
          }
        }
      }

      .nav {
        width: 468px;
        text-align: left;
        margin-left: 250px;

        .nav-title {
          font-size: 24px;
          font-family: Poppins-SemiBold;
          font-weight: 600;
          color: #ffffff;
          line-height: 28px;
          margin-bottom: 26px;
          user-select: none;
        }

        .nav-list {
          .nav-itm {
            margin-bottom: 25px;
            color: #fff;
            font-family: Poppins-Regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            user-select: none;
            cursor: pointer;
          }
        }
      }

      .page {
        width: 234px;
        user-select: none;

        .page-title {
          width: 100%;
          margin-bottom: 24px;
          color: #fff;
          font-family: Poppins-SemiBold;
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
        }

        .page-info {
          width: 100%;
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          font-family: Poppins-Regular;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          color: #fff;

          .page-item {
            width: 50%;
            margin-bottom: 20px;
            position: relative;
          }
        }
      }
    }
  }

  .copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px;
    box-sizing: border-box;

    .copy {
      font-family: Lato;
      font-size: 12px;
      color: #999;
      font-weight: bold;
      line-height: 22px;
    }
  }
}

@media only screen and (max-width: 690px) {
  .footer {
    .footer-nav {
      padding: 55px 0;

      .warpper {
        // flex-direction: column;
        flex-wrap: wrap;

        .left {
          .logo {
            margin: 0 auto;
            margin-bottom: 30px;
          }

          .phone {
            font-size: 12px;
            margin: 0 auto;
            margin-bottom: 30px;
          }
        }

        .nav {
          width: 50%;
          margin-bottom: 40px;
          margin-left: 0;

          .nav-title {
            text-align: left;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 24px;
          }

          .nav-list {
            .nav-itm {
              font-size: 12px;
              line-height: 14px;
              text-align: left;
              margin-bottom: 19px;
            }
          }
        }

        .page {
          width: 50%;

          .page-title {
            text-align: left;
            font-size: 16px;
            line-height: 19px;
          }

          .page-info {
            .page-item {
              font-size: 12px;
              line-height: 14px;
              text-align: left;
              margin-bottom: 19px;

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 690px) and (max-width: 999px) {
  .footer {
    .footer-nav {
      padding: 55px 0;

      .warpper {
        flex-direction: column;

        .left {
          .logo {
            margin: 0 auto;
            margin-bottom: 30px;
          }

          .phone {
            margin: 0 auto;
            margin-bottom: 30px;
          }
        }

        .nav {
          width: 50%;
          margin-bottom: 40px;
          margin-left: 0;

          .nav-title {
            text-align: left;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 24px;
          }

          .nav-list {
            .nav-itm {
              font-size: 12px;
              line-height: 14px;
              text-align: left;
              margin-bottom: 19px;
            }
          }
        }

        .page {
          width: 50%;

          .page-title {
            text-align: left;
            font-size: 16px;
            line-height: 19px;
          }

          .page-info {
            .page-item {
              font-size: 12px;
              line-height: 14px;
              text-align: left;
              margin-bottom: 19px;

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
