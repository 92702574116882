<template>
  <div class="header" ref="header">
    <div class="container">
      <div class="top">
        <div class="logo" @click="goHome">
          <img src="~@/assets/images/Cars4Us-Logo-Dark.png" alt="" ref="logo" />
        </div>
        <div class="nav">
          <ul class="sf-menu">
            <li
              class="menu-item"
              ref="item"
              v-for="(item, index) in navList"
              :key="index"
            >
              <div
                @click="current(index, item.name)"
                :class="currentIndex == index ? 'current-index info' : 'info'"
              >
                {{ item.name }}
              </div>
              <ul
                class="sub-menu"
                ref="menu"
                v-if="item.children"
                @mouseleave="itemMouseOut"
              >
                <li
                  :class="
                    i.id == pageId
                      ? 'sub-menu-item current-page-id'
                      : 'sub-menu-item'
                  "
                  v-for="(i, key) in item.children"
                  :key="i.id"
                  @mousemove="itemMouseMove(key)"
                  :ref="`item${i.id}`"
                  @click="goPage(i.id)"
                >
                  <div>
                    {{ i.name }}
                  </div>
                  <span class="sf-sub-indicator"
                    ><i class="fa fa-angle-right icon-in-menu"></i
                  ></span>
                  <ul class="sub-sub-menu" v-show="moveIndex === key">
                    <li
                      :class="
                        i.id == pageId && j.id == cId
                          ? 'sub-sub-menu-item current-c-id'
                          : 'sub-sub-menu-item'
                      "
                      v-for="j in i.children"
                      :key="j.id"
                      @click.stop="goPage(i.id, j.id)"
                    >
                      <div>
                        {{ j.name }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li
              class="menu-item"
              style="
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 160px;
                  height: 40px;
                  background: #f5c03e;
                  color: #404040;
                  border-radius: 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                "
                @click="goStart"
              >
                Get Started
              </div>
            </li>
            <li class="menu-item" ref="phone">
              <div class="info">
                <a href="1300 849 879"
                  ><i class="fa fa-phone"></i>&nbsp; 1300 849 879</a
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="right-button" @click="sideBarClick">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div
      :style="{ display: side ? 'block' : 'none' }"
      :class="side ? 'side-nav' : ''"
    >
      <div class="top-icon">
        <div class="close" @click="sideBarClick">
          <div class="close-icon">
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="nav">
        <ul class="sf-menu">
          <li
            class="menu-item"
            ref="item"
            v-for="(item, index) in navList"
            :key="index"
          >
            <div @click="current(index, item.name)" class="info">
              {{ item.name }}
              <span
                class="sf-sub-indicator"
                v-if="item.children"
                @click.stop="subShow(index)"
                ><i
                  :class="
                    !item.show
                      ? 'fa fa-angle-down icon-in-menu'
                      : 'fa fa-angle-up icon-in-menu'
                  "
                ></i
              ></span>
            </div>
            <ul
              :class="navList[0].show ? 'sub-menu current-show' : 'sub-menu'"
              ref="menu"
              v-if="item.children"
              @mouseleave="itemMouseOut"
            >
              <li
                :class="
                  i.id == pageId
                    ? 'sub-menu-item current-page-id'
                    : 'sub-menu-item'
                "
                v-for="(i, key) in item.children"
                :key="i.id"
                @mousemove="itemMouseMove(key)"
                :ref="`item${i.id}`"
                @click="goPage(i.id)"
              >
                <div class="info">
                  <div>
                    {{ i.name }}
                  </div>
                  <span class="sf-sub-indicator" @click.stop="erShow(i.id)"
                    ><i
                      :class="
                        !i.show
                          ? 'fa fa-angle-down icon-in-menu'
                          : 'fa fa-angle-up icon-in-menu'
                      "
                    ></i
                  ></span>
                </div>
                <ul
                  :class="i.show ? 'cur-sub-show sub-sub-menu' : 'sub-sub-menu'"
                  v-show="moveIndex === key"
                >
                  <li
                    :class="
                      i.id == pageId && j.id == cId
                        ? 'sub-sub-menu-item current-c-id'
                        : 'sub-sub-menu-item'
                    "
                    v-for="j in i.children"
                    :key="j.id"
                    @click.stop="goPage(i.id, j.id)"
                  >
                    <div>
                      {{ j.name }}
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="menu-item">
            <div @click="goStart">Get Started</div>
          </li>
          <li class="menu-item" ref="phone">
            <div class="info">
              <a href="tel:1300 849 879"
                ><i class="fa fa-phone"></i>&nbsp; 1300 849 879</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    pageId: {
      default: 0,
    },
    cId: {
      default: 0,
    },
  },
  computed: {
    ...mapState(["side"]),
  },
  data() {
    return {
      // 导航
      moveIndex: "",
      navList: [
        {
          name: "Locations",
          show: false,
          children: [
            {
              name: "Australian Capital Territory",
              id: 1,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Canberra",
                },
                {
                  id: 2,
                  name: "Barton",
                },
                {
                  id: 3,
                  name: "Yarralumla",
                },
                {
                  id: 4,
                  name: "Forrest",
                },
                {
                  id: 5,
                  name: "Deakin",
                },
                {
                  id: 6,
                  name: "Red Hill",
                },
                {
                  id: 7,
                  name: "Hughes",
                },
                {
                  id: 8,
                  name: "Kambah",
                },
                {
                  id: 9,
                  name: "Binalong",
                },
                {
                  id: 10,
                  name: "Sutton",
                },
                {
                  id: 11,
                  name: "Bookham",
                },
                {
                  id: 12,
                  name: "Lyons",
                },
                {
                  id: 13,
                  name: "Bowning",
                },
                {
                  id: 14,
                  name: "Murrumbateman",
                },
                {
                  id: 15,
                  name: "Hall",
                },
              ],
            },
            {
              name: "New South Wales",
              id: 2,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Albury",
                },
                {
                  id: 2,
                  name: "Armidale",
                },
                {
                  id: 3,
                  name: "Central Coast",
                },
                {
                  id: 4,
                  name: "Coffs Harbour",
                },
                {
                  id: 5,
                  name: "Dubbo",
                },
                {
                  id: 6,
                  name: "Lismore",
                },
                {
                  id: 7,
                  name: "Maitland",
                },
                {
                  id: 8,
                  name: "Orange",
                },
                {
                  id: 9,
                  name: "Newcastle",
                },
                {
                  id: 10,
                  name: "Port Macquarie",
                },
                {
                  id: 11,
                  name: "Sydney",
                },
                {
                  id: 12,
                  name: "Tamworth",
                },
                {
                  id: 13,
                  name: "Wagga Wagga",
                },
                {
                  id: 14,
                  name: "Wollongong",
                },
              ],
            },
            {
              name: "Northern Territory",
              id: 3,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Alice Springs",
                },
                {
                  id: 2,
                  name: "Darwin",
                },
                {
                  id: 3,
                  name: "Galiwinku",
                },
                {
                  id: 4,
                  name: "Jabiru",
                },
                {
                  id: 5,
                  name: "Katherine",
                },
                {
                  id: 6,
                  name: "Litchfield",
                },
                {
                  id: 7,
                  name: "Maningrida",
                },
                {
                  id: 8,
                  name: "Nguiu",
                },
                {
                  id: 9,
                  name: "Nhulunbuy",
                },
                {
                  id: 10,
                  name: "Palmerston-East Arm",
                },
                {
                  id: 11,
                  name: "Port Keats",
                },
                {
                  id: 12,
                  name: "Tennant Creek",
                },
                {
                  id: 13,
                  name: "Wadeye/Victoria-Daly",
                },
                {
                  id: 14,
                  name: "Yulara",
                },
              ],
            },
            {
              name: "Queensland",
              id: 4,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Beenleigh",
                },
                {
                  id: 2,
                  name: "Brisbane",
                },
                {
                  id: 3,
                  name: "Cairns",
                },
                {
                  id: 4,
                  name: "Gold Coast",
                },
                {
                  id: 5,
                  name: "Ipswich",
                },
                {
                  id: 6,
                  name: "Mackay",
                },
                {
                  id: 7,
                  name: "Rockhampton",
                },
                {
                  id: 8,
                  name: "Sunshine Coast",
                },
                {
                  id: 9,
                  name: "Toowoomba",
                },
                {
                  id: 10,
                  name: "Townsville",
                },
                {
                  id: 11,
                  name: "Bundaberg",
                },
                {
                  id: 12,
                  name: "Gladstone",
                },
                {
                  id: 13,
                  name: "Emerald",
                },
                {
                  id: 14,
                  name: "Mount Isa",
                },
              ],
            },
            {
              name: "South Australia",
              id: 5,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Adelaide",
                },
                {
                  id: 2,
                  name: "Gawler",
                },
                {
                  id: 3,
                  name: "Goolwa",
                },
                {
                  id: 4,
                  name: "Millicent",
                },
                {
                  id: 5,
                  name: "Mount Barker",
                },
                {
                  id: 6,
                  name: "Mount Gambier",
                },
                {
                  id: 7,
                  name: "Murray Bridge",
                },
                {
                  id: 8,
                  name: "Nairne",
                },
                {
                  id: 9,
                  name: "Nuriootpa",
                },
                {
                  id: 10,
                  name: "Port Augusta",
                },
                {
                  id: 11,
                  name: "Port Lincoln",
                },
                {
                  id: 12,
                  name: "Port Pirie",
                },
                {
                  id: 13,
                  name: "Strathalbyn",
                },
                {
                  id: 14,
                  name: "Victor Harbor",
                },
                {
                  id: 15,
                  name: "Whyalla",
                },
              ],
            },
            {
              name: "Tasmania",
              id: 6,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Blackmans Bay",
                },
                {
                  id: 2,
                  name: "Bridgewater",
                },
                {
                  id: 3,
                  name: "Burnie",
                },
                {
                  id: 4,
                  name: "Devonport",
                },
                {
                  id: 5,
                  name: "Gagebrook",
                },
                {
                  id: 6,
                  name: "George Town",
                },
                {
                  id: 7,
                  name: "Hobart",
                },
                {
                  id: 8,
                  name: "Kingston",
                },
                {
                  id: 9,
                  name: "Launceston",
                },
                {
                  id: 10,
                  name: "Longford",
                },
                {
                  id: 11,
                  name: "New Norfolk",
                },
                {
                  id: 12,
                  name: "Smithton",
                },
                {
                  id: 13,
                  name: "Somerset",
                },
                {
                  id: 14,
                  name: "Ulverstone",
                },
                {
                  id: 15,
                  name: "Wynyard",
                },
              ],
            },
            {
              name: "Victoria",
              id: 7,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Ballarat",
                },
                {
                  id: 2,
                  name: "Bendigo",
                },
                {
                  id: 3,
                  name: "Geelong",
                },
                {
                  id: 4,
                  name: "Melbourne",
                },
                {
                  id: 5,
                  name: "Melton",
                },
                {
                  id: 6,
                  name: "Mildura",
                },
                {
                  id: 7,
                  name: "Ocean Grove",
                },
                {
                  id: 8,
                  name: "Pakenham",
                },
                {
                  id: 9,
                  name: "Shepparton",
                },
                {
                  id: 10,
                  name: "Sunbury",
                },
                {
                  id: 11,
                  name: "Torquay",
                },
                {
                  id: 12,
                  name: "Traralgon",
                },
                {
                  id: 13,
                  name: "Wangaratta",
                },
                {
                  id: 14,
                  name: "Warrnambool",
                },
                {
                  id: 15,
                  name: "Wodonga",
                },
              ],
            },
            {
              name: "Western Australia",
              id: 8,
              show: false,
              children: [
                {
                  id: 1,
                  name: "Albany",
                },
                {
                  id: 2,
                  name: "Broome",
                },
                {
                  id: 3,
                  name: "Bunbury",
                },
                {
                  id: 4,
                  name: "Busselton",
                },
                {
                  id: 5,
                  name: "Collie",
                },
                {
                  id: 6,
                  name: "Dunsborough",
                },
                {
                  id: 7,
                  name: "Esperance",
                },
                {
                  id: 8,
                  name: "Geraldton",
                },
                {
                  id: 9,
                  name: "Kalgoorlie-Boulder",
                },
                {
                  id: 10,
                  name: "Karratha",
                },
                {
                  id: 11,
                  name: "Margaret River",
                },
                {
                  id: 12,
                  name: "Northan",
                },
                {
                  id: 13,
                  name: "Perth",
                },
                {
                  id: 14,
                  name: "Port Hedland",
                },
                {
                  id: 15,
                  name: "Yanchep",
                },
              ],
            },
          ],
        },
        {
          name: "FAQs",
        },
        {
          name: "About",
        },
      ],
      currentIndex: 3,
    };
  },
  methods: {
    current(index, path) {
      this.currentIndex = index;
      this.$router.push({ name: path, query: { index } });
    },
    // 鼠标移入
    itemMouseMove(index) {
      this.moveIndex = index;
    },
    itemMouseOut() {
      this.moveIndex = "";
    },
    scrollHandle(e) {
      this.$nextTick(() => {
        let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
        if (top >= 300) {
          this.$refs.header.style.height = "60px";
          this.$refs.item.forEach((item) => {
            item.style.padding = "22px 10px";
          });
          this.$refs.phone.style.padding = "22px 10px";
          this.$refs.logo.style.width = "110px";
          this.$refs.logo.style.height = "30px";
          this.$refs.menu[0].style.top = "60px";
        } else {
          this.$refs.header.style.height = "";
          this.$refs.item.forEach((item) => {
            item.style.padding = "";
          });
          this.$refs.phone.style.padding = "";
          this.$refs.logo.style.width = "";
          this.$refs.logo.style.height = "";
          this.$refs.menu[0].style.top = "";
        }
      });
    },
    sideBarClick() {
      window.scrollTo(0, 0);
      this.$store.commit("changeSide");
    },
    goStart() {
      this.$router.push("/kong");
    },
    goHome() {
      this.$router.push("/");
    },
    goPage(id, cId) {
      this.$router.push({ name: "Locations", query: { index: 0, id, cId } });
    },
    subShow(index) {
      this.navList[index].show = !this.navList[index].show;
    },
    erShow(id) {
      this.navList[0].children.forEach((item) => {
        if (item.id == id) {
          item.show = !item.show;
          if (item.show) {
            this.navList[0].children.forEach((i) => {
              if (i.id != id) {
                i.show = false;
              }
            });
          }
        }
      });
    },
  },
  created() {
    if (this.$route.query.index || this.$route.query.index === 0) {
      this.currentIndex = this.$route.query.index;
    }
  },
};
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 9999;
  box-shadow: 0 3px 45px rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
  .top {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 9998;
    .logo {
      width: 256px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
        height: 70px;
      }
    }
    .nav {
      height: 100%;
      position: relative;
      .sf-menu {
        height: 100%;
        display: flex;
        align-items: center;
        .menu-item {
          height: 100%;
          padding: 50px 10px;
          box-sizing: border-box;
          font-family: Poppins-SemiBold;
          text-transform: none;
          font-size: 18px;
          line-height: 18px;
          font-weight: 600;
          color: #212121;
          user-select: none;

          .info {
            cursor: pointer;
            a:hover {
              color: #212121;
            }
            &::after {
              content: "";
              display: block;
              width: 0;
              height: 2px;
              background: #f5c03e;
              transition: all 0.3s;
            }
            &:hover {
              &::after {
                width: 100%;
              }
            }
            &:nth-child(4) {
              &::after {
                content: "";
                display: none;
                width: 0;
                height: 2px;
                background: #fff;
              }
            }
          }
          .current-index {
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 2px;
              background: #f5c03e;
            }
          }
          .sub-menu {
            position: absolute;
            border: 1px solid #e9e9e9;
            background-color: #fff;
            padding: 20px;
            width: 240px;
            display: none;
            top: 120px;
            .sub-menu-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 40px;
              cursor: pointer;
              &:hover {
                background: #f5c03e;
                & {
                  .fa-angle-right {
                    color: #fff;
                  }
                }
              }
              .sub-sub-menu {
                position: absolute;
                border: 1px solid #e9e9e9;
                background-color: #fff;
                padding: 20px;
                width: 240px;
                // opacity: 0;
                left: 239px;
                top: -1px;
                .sub-sub-menu-item {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 40px;
                  cursor: pointer;
                  &:hover {
                    background: #f5c03e;
                  }
                }
                .current-c-id {
                  background: #f5c03e;
                }
              }
            }
            .current-page-id {
              background: #f5c03e;
              & {
                .fa-angle-right {
                  color: #fff;
                }
              }
            }
          }
          &:first-child:hover {
            .sub-menu {
              display: block;
            }
          }
        }
      }
    }
    .right-button {
      display: none;
    }
  }
  .side-nav {
    width: 80%;
    height: 100%;
    padding: 10% 12%;
    box-sizing: border-box;
    position: fixed;
    right: -80%;
    top: -100px;
    font-family: Poppins;
    .top-icon {
      display: flex;
      justify-content: flex-end;
      margin-right: 24px;
      margin-bottom: 16px;
      .close {
        width: 50px;
        height: 50px;
        background: #212121;
        border-radius: 50%;
        position: relative;
        .close-icon {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .line {
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            &:first-child {
              transform-origin: center;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &:last-child {
              transform-origin: center;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
      }
    }
    .sf-menu {
      .menu-item {
        font-size: 16px;
        line-height: 19px;
        font-weight: 800;
        margin-bottom: 12px;
        font-family: Poppins-ExtraBold;
        .info {
          display: flex;
          justify-content: space-between;
          span {
            margin-right: 5%;
            font-size: 16px;
          }
        }
        .sub-menu {
          display: none;
          padding: 10px 0;
          margin-left: 20px;
          .sub-menu-item {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            font-family: Poppins-Medium;
            margin-bottom: 12px;
            .info {
              display: flex;
              justify-content: space-between;
              span {
                margin-right: 5%;
                font-size: 14px;
              }
            }
            .sub-sub-menu {
              display: none;
              padding: 20px;
              .sub-sub-menu-item {
                margin-bottom: 10px;
              }
            }
            .cur-sub-show {
              display: block;
            }
          }
        }
        .current-show {
          display: block;
        }
      }
    }
  }
}
@media only screen and (min-width: 690px) and (max-width: 999px) {
  .header {
    width: 100%;
    padding: 12px 0;
    height: 64px;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 100%;
      .logo {
        width: 147px;
        height: 40px;
        img {
          width: 100%;
          height: 40px;
        }
      }
      .nav {
        display: none;
      }
      .right-button {
        display: block;
        width: 22px;
        height: 22px;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
        .line {
          width: 100%;
          height: 2px;
          background: #999;
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .header {
    width: 100%;
    padding: 12px 0;
    height: 64px;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 100%;
      .logo {
        width: 147px;
        height: 40px;
        img {
          width: 100%;
          height: 40px;
        }
      }
      .nav {
        display: none;
      }
      .right-button {
        display: block;
        width: 22px;
        height: 22px;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
        .line {
          width: 100%;
          height: 2px;
          background: #999;
        }
      }
    }
  }
}
</style>
